// fashion img 
import img1 from '../../assets/img/product-image/product1.jpg'
import img2 from '../../assets/img/product-image/product2.jpg'
import img3 from '../../assets/img/product-image/product3.jpg'
import img4 from '../../assets/img/product-image/product4.jpg'
import img5 from '../../assets/img/product-image/product5.jpg'
import img6 from '../../assets/img/product-image/product6.jpg'
import img7 from '../../assets/img/product-image/product7.jpg'
import img8 from '../../assets/img/product-image/product8.jpg'
import img9 from '../../assets/img/product-image/product9.jpg'
import img10 from '../../assets/img/product-image/product10.jpg'

// furniture img 
import imgf1 from '../../assets/img/furniture/product/1.jpg'
import imgf2 from '../../assets/img/furniture/product/2.jpg'
import imgf3 from '../../assets/img/furniture/product/3.jpg'
import imgf4 from '../../assets/img/furniture/product/4.jpg'
import imgf5 from '../../assets/img/furniture/product/5.jpg'
import imgf6 from '../../assets/img/furniture/product/6.jpg'
import imgf7 from '../../assets/img/furniture/product/7.jpg'
import imgf8 from '../../assets/img/furniture/product/8.jpg'
import imgf9 from '../../assets/img/furniture/product/9.jpg'
import imgf10 from '../../assets/img/furniture/product/10.jpg'

// electronics img 
import imge1 from '../../assets/img/electronics/product/1.jpg'
import imge2 from '../../assets/img/electronics/product/2.jpg'
import imge3 from '../../assets/img/electronics/product/3.jpg'
import imge4 from '../../assets/img/electronics/product/4.jpg'
import imge5 from '../../assets/img/electronics/product/5.jpg'
import imge6 from '../../assets/img/electronics/product/6.jpg'
import imge7 from '../../assets/img/electronics/product/7.jpg'
import imge8 from '../../assets/img/electronics/product/8.jpg'
import imge9 from '../../assets/img/electronics/product/9.jpg'
import imge10 from '../../assets/img/electronics/product/10.jpg'

// pharmacy img 
import Pro1 from '../../assets/img/pharmacy/product/pro-1.png'
import Pro2 from '../../assets/img/pharmacy/product/pro-2.png'
import Pro3 from '../../assets/img/pharmacy/product/pro-3.png'
import Pro4 from '../../assets/img/pharmacy/product/pro-4.png'


export const ProductData = [
    {
        id: 1,
        labels: "Trending",
        category: "fashion",
        img: img1,
        hover_img: img1,
        title: "Leather Belts For Men",
        price: 38,
        description: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. 
        The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.`,
        rating: {
            rate: 3.9,
            count: 30
        },
        color: [
            {
                color: "green",
                img: img8,
                quantity: 1,
            },
            {
                color: "red",
                img: img9,
                quantity: 1,
            },
            {
                color: "blue",
                img: img7,
                quantity: 1,
            },
        ]
    },
    {
        id: 2,
        labels: "45% OFF",
        category: "fashion",
        img: img2,
        hover_img: img2,
        title: "Cowboy Boots for Women",
        price: 72,
        description: `Vivamus suscipit tortor eget felis porttitor volutpat. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
         Proin eget tortor risus. Nulla porttitor accumsan tincidunt. Pellentesque in ipsum id orci porta dapibus.`,
        rating: {
            rate: 3.3,
            count: 80
        },
        color: [
            {
                color: "green",
                img: img6,
                quantity: 1,
            },
            {
                color: "red",
                img: img10,
                quantity: 1,
            },
            {
                color: "blue",
                img: img5,
                quantity: 1,
            },
        ]
    },
    {
        id: 3,
        labels: "50% OFF",
        category: "fashion",
        img: img3,
        hover_img: img3,
        title: "Leather Jacket for Men",
        price: 34,
        description: `Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Vestibulum ante ipsum primis in 
        faucibus orci luctus et ultrices posuere cubilia Curae;`,
        rating: {
            rate: 4.9,
            count: 156
        },
        color: [
            {
                color: "green",
                img: img6,
                quantity: 1,
            },
            {
                color: "red",
                img: img7,
                quantity: 1,
            },
            {
                color: "blue",
                img: img8,
                quantity: 1,
            },
        ]
    },
    {
        id: 4,
        labels: "Hot",
        category: "fashion",
        img: img4,
        hover_img: img6,
        title: "Cowboy Hats for Men",
        price: 43,
        description: `Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Vestibulum ac diam sit 
        amet quam vehicula elementum sed sit amet dui. Quisque velit nisi, pretium ut lacinia in, elementum id enim.`,
        rating: {
            rate: 1.9,
            count: 190
        },
        color: [
            {
                color: "green",
                img: img3,
                quantity: 1,
            },
            {
                color: "red",
                img: img9,
                quantity: 1,
            },
            {
                color: "blue",
                img: img5,
                quantity: 1,
            },
        ]
    },
    {
        id: 5,
        labels: "Upcoming",
        category: "fashion",
        img: img5,
        hover_img: img5,
        title: "Leather Boots for Women",
        price: 52,
        description: `Donec sollicitudin molestie malesuada. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Vivamus suscipit tortor eget felis porttitor volutpat. Nulla quis lorem ut libero malesuada feugiat.`,
        rating: {
            rate: 3.6,
            count: 20
        },
        color: [
            {
                color: "green",
                img: img10,
                quantity: 1,
            },
            {
                color: "red",
                img: img9,
                quantity: 1,
            },
            {
                color: "blue",
                img: img2,
                quantity: 1,
            },
        ]
    },
    {
        id: 6,
        labels: "Upcoming",
        category: "fashion",
        img: img6,
        hover_img: img4,
        title: "Cowboy Hat for Men",
        price: 14,
        description: `Nulla quis lorem ut libero malesuada feugiat. Quisque velit nisi, pretium ut lacinia in, elementum id enim. 
        Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Donec sollicitudin molestie malesuada`,
        rating: {
            rate: 3.9,
            count: 120
        },
        color: [
            {
                color: "green",
                img: img7,
                quantity: 1,
            },
            {
                color: "red",
                img: img8,
                quantity: 1,
            },
            {
                color: "blue",
                img: img9,
                quantity: 1,
            },
        ]
    },
    {
        id: 7,
        labels: "Trending",
        category: "fashion",
        img: img7,
        hover_img: img7,
        title: "Cowboy Boots for Men",
        price: 41,
        description: `Donec rutrum congue leo eget malesuada. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. 
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque velit nisi, pretium ut lacinia in, elementum id enim.`,
        rating: {
            "rate": 1.9,
            "count": 10
        },
        color: [
            {
                color: "green",
                img: img2,
                quantity: 1,
            },
            {
                color: "red",
                img: img8,
                quantity: 1,
            },
            {
                color: "blue",
                img: img1,
                quantity: 1,
            },
        ]
    },
    {
        id: 8,
        labels: "Best Selling",
        category: "fashion",
        img: img8,
        hover_img: img8,
        title: "Leather Shoes",
        price: 21,
        description: `Nulla quis lorem ut libero malesuada feugiat. Donec sollicitudin molestie malesuada.
         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rutrum congue leo eget malesuada.`,
        rating: {
            rate: 2.9,
            count: 20
        },
        color: [
            {
                color: "green",
                img: img9,
                quantity: 1,
            },
            {
                color: "red",
                img: img8,
                quantity: 1,
            },
            {
                color: "blue",
                img: img10,
                quantity: 1,
            },
        ]
    },
    {
        id: 9,
        labels: "Popular",
        category: "fashion",
        img: img9,
        hover_img: img1,
        title: "Boho Tops for Girls",
        price: 46,
        description: ` Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. 
        Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada.`,
        rating: {
            rate: 3.9,
            count: 120
        },
        color: [
            {
                color: "green",
                img: img5,
                quantity: 1,
            },
            {
                color: "red",
                img: img6,
                quantity: 1,
            },
            {
                color: "blue",
                img: img7,
                quantity: 1,
            },
        ]
    },
    {
        id: 10,
        labels: "Top Choice",
        category: "fashion",
        img: img10,
        hover_img: img3,
        title: "Skater Dress",
        price: 74,
        description: `Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec
         rutrum congue leo eget malesuada. Sed porttitor lectus nibh. Proin eget tortor risus. Curabitur aliquet quam id dui posuere blandit. `,
        rating: {
            rate: 3.9,
            count: 220
        },
        color: [
            {
                color: "green",
                img: img2,
                quantity: 1,
            },
            {
                color: "red",
                img: img3,
                quantity: 1,
            },
            {
                color: "blue",
                img: img4,
                quantity: 1,
            },
        ]
    },


    // furniture data
    
]